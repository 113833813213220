import { render, staticRenderFns } from "./RoleSelect.vue?vue&type=template&id=34ef0255&scoped=true&"
import script from "./RoleSelect.vue?vue&type=script&lang=js&"
export * from "./RoleSelect.vue?vue&type=script&lang=js&"
import style0 from "./RoleSelect.vue?vue&type=style&index=0&id=34ef0255&prod&lang=css&"
import style1 from "./RoleSelect.vue?vue&type=style&index=1&id=34ef0255&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34ef0255",
  null
  
)

export default component.exports