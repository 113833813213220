<template>
  <div>
    <div class="row justify-content-center mt-2">
      <div class="col-md-4">
          <div class="card overflow-hidden">
              <div class="card-header">
                  <div class="avatar-md profile-user-wid">
                      <span class="avatar-title rounded-circle bg-light">
                          <img class="mx-auto d-block" src="@/assets/images/login/logo.png" height="80" />
                      </span>
                  </div>
              </div>
              <div class="card-body">
                <b-alert :show="errorAlert" variant=" " dismissible fade class="text-white bg-danger">
                  <div class="iq-alert-icon">
                    <i class="ri-information-line"></i>
                  </div>
                  <div class="iq-alert-text">{{ errorMsg }}</div>
                </b-alert>
                <div class="text-center" v-if="loading">
                  <b-spinner variant="primary" label="Text Centered"></b-spinner>
                  <br/>{{ message }}
                </div>
                <div class="col-12" v-if="userRoles.length > 1">
                  <h3 class="mb-3 login-text">{{ $t('authentication.selectRole') }}</h3>
                  <hr/>
                  <slot v-for="(userRole, index) in userRoles">
                    <b-form-radio
                      :key="index"
                      class="col-form-label-sm font-weight-bold mb-0"
                      @change="userRoleSelect(userRole.id)"
                      name="checked"
                      :unchecked-value=false
                      >
                      {{ ($i18n.locale === 'bn') ? userRole.role_name_bn  :  userRole.role_name  }}
                    </b-form-radio>
                  </slot>
                  <slot>
                    <hr/>
                    <button class="btn btn-primary btn-block" v-on:click="SubmitData()">
                      {{ $t('globalTrans.submit') }}
                    </button>
                  </slot>
                </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import RestApi, { authServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import { authUserRolesApi, componentsByRoleApi, componentsOfSuperAdminApi } from '../api/routes'

export default {
  data: () => ({
    loading: true,
    errorAlert: false,
    errorMsg: '',
    message: '',
    userRoles: [],
    selectedRole: 0
  }),
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  created () {
    this.$store.dispatch('Auth/setActiveRole', 0)
    this.getUserRoles()
  },
  methods: {
    getUserRoles () {
      this.message = this.$t('authentication.checkingRole')
      const authUserId = this.$store.state.Auth.accessUserId
      RestApi.getData(authServiceBaseUrl, `${authUserRolesApi}/${authUserId}`, {}).then(response => {
        this.userRoles = response.data
        const totalRoles = this.userRoles.length
        this.$store.dispatch('Auth/setAuthUserRoles', this.userRoles)

        if (totalRoles === 0) {
          this.$toast.error({
            title: 'Error',
            message: this.$t('authentication.noRoles')
          })
          this.$router.push('/auth/login')
        } else if (totalRoles === 1) {
          this.selectedRole = this.userRoles[0].id
          this.getComponentsByRole()
          return
        }
        this.loading = false
      })
    },
    SubmitData () {
      if (this.selectedRole) {
        this.getComponentsByRole()
      } else {
        this.errorAlert = true
        this.errorMsg = this.$t('authentication.selectRole')
      }
    },
    userRoleSelect (roleId) {
      this.selectedRole = roleId
    },
    getComponentsByRole () {
      let baseUrl = authServiceBaseUrl
      let api = `${componentsByRoleApi}/${this.selectedRole}`
      if (this.selectedRole === 1) {
        baseUrl = commonServiceBaseUrl
        api = componentsOfSuperAdminApi
      }

      this.loading = true
      this.message = this.$t('authentication.checkingPermissions')
      RestApi.getData(baseUrl, api).then(response => {
        if (!response.success) {
          this.$toast.error({
            title: 'Error',
            message: this.$t(response.message_i18n_code)
          })
          this.$router.push('/auth/login')
          return
        } else if (response.data.length === 0) {
          this.$toast.error({
            title: 'Error',
            message: this.$t('authentication.noPrivilege')
          })
          this.$router.push('/auth/login')
          return
        }
        this.$store.dispatch('Auth/setActiveRole', this.selectedRole)
        this.$store.dispatch('Auth/setAuthorizedComponents', response.data)
        this.loading = false
        if (this.$store.state.Auth.authUser.dashboard_user !== 0) {
          this.$router.push('/ministry/dashboard/market-rate')
        } else {
          this.$router.push('/auth/dashboard')
        }
      }, error => {
        this.loading = false
        this.$router.push('/auth/login')
        if (error) {
          //
        }
      })
    }
  }
}
</script>
<style>
/* @import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;900&display=swap');
</style>

<style scoped>
        .overflow-hidden {
          box-shadow: 1px 4px 12px 2px black;
        }
        .card{border: 0px!important;}
        .card-header{
          background-color: #c7cef8 !important; border-bottom: 0px!important;
        }
        .btn-custom{background-color: #596DE1; color: #fff;}
        input#email, input#password{
          border: 1px solid #c1c1c1;
        }
        .invalid-feedback{
          margin-left: 43px;
        }
</style>
